import GDPRCookieConsent from "./modules/GDPRCookieConsent"

//link hamburger menu functions the buttons
jQuery(document).ready(function () {    
    var gdprCookieConsent = new GDPRCookieConsent('.hc-gdprcc-manage-consent-link',
                                                'button.cky-btn-revisit');
    
    if (gdprCookieConsent) {
        gdprCookieConsent.install();
    }         
});

