/* 
 * Click nbfs://nbhost/SystemFileSystem/Templates/Licenses/license-default.txt to change this license
 * Click nbfs://nbhost/SystemFileSystem/Templates/ClientSide/javascript.js to edit this template
 */

class GDPRCookieConsent {
    #show_banner_links = null;
    #plugin_button = null;

    constructor(link_selector, plugin_button_selector) {
        if (link_selector) {
            this.#show_banner_links = jQuery(link_selector);
            if (!this.#show_banner_links.length) {
                console.warn("No elements to trigger the display of cookie settings was found using selector: " + link_selector + "!");
            }
        } else {
            console.warn("The selector for links to show cookie settigs was not set!");
        } 
        
        if (plugin_button_selector) {
            this.#plugin_button = jQuery(plugin_button_selector);
            if (!this.#plugin_button.length) {
                console.warn("The plugin consent button was not found!");
            }
        }
    }

    showBannerOnClick(event) {
        try {                        
            if (event) {
                event.preventDefault();
            }
            
            if (typeof CLI !== 'undefined' && CLI !== null) {
                CLI.displayHeader();
            } else {
                //Try the new version with the button
                if (this.#plugin_button) {
                    this.#plugin_button.click();
                }
            }                   
        } catch (err) {
            console.error("Failed to trigger the display of cookie consent settings banner! Reson: " + err.message);
        }
    }

    install() {
        if (this.#show_banner_links) {
            this.#show_banner_links.on("click", this.showBannerOnClick.bind(this));
        }
    }
}

export default GDPRCookieConsent;